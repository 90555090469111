import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";
import { userContext } from "src/context/UserContext";
import { url } from "src/helpers";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Signup() {
  const { setUser, setLoad } = useContext(userContext);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("1");
  const [business, setBusiness] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [termCondition, setTermCondition] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleCPasswordVisibility = () => {
    setShowCPassword((prevShowPassword) => !prevShowPassword);
  };

  const verifyCaptcha = () => {
    setTermCondition(true);
  };
  const acceptTermsCondition = (e) => {
    setCaptcha(e.target.checked);
  };

  const history = useHistory();
  function submitLogin(e) {
    e.preventDefault();

    async function send() {
      let data = {
        first_name: fname,
        last_name: lname,
        email: email,
        password: password,
        phone_number: mobile,
        business_name: business,
        user_type: role,
      };
      const response = await fetch(url + "register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setLoad(false);

      if (response.ok == true) {
        const data = await response.json();
        if (data.status == 200) {
          toast.success(data.message);
          history.push("/login");
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Oops something went wrong!");
      }
    }

    if (cpassword == password) {
      setLoad(true);
      send().catch((err) => {
        setLoad(false);
        toast.error(err.message);
      });
    } else {
      toast.error("Password & confirm password are not match");
    }
  }

  return (
    <>
      <Navbar type="full" />
      <div className="login-page-div custom-main-section">
        <div className="inner-box d-flex flex-column align-items-center">
          <form onSubmit={(e) => submitLogin(e)}>
            <div className="row w-100">
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-user icon"></i>
                </span>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
              </div>
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-user icon"></i>
                </span>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
              </div>
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-envelope icon"></i>
                </span>
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-phone icon"></i>
                </span>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="+254712234567"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-truck icon"></i>
                </span>
                <select
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="1">Shipper</option>
                  <option value="2">Transporter</option>
                </select>
              </div>
              <div className="input-group col-md-6 my-2">
                <span className="input-group-text">
                  <i class="fa fa-briefcase icon"></i>
                </span>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Business Name"
                  value={business}
                  onChange={(e) => setBusiness(e.target.value)}
                />
              </div>
              <div className="input-group col-md-6 my-2">
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="input-group-text"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>
                </span>
              </div>
              <div className="input-group col-md-6 my-2">
                <input
                  required
                  type={showCPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm Password"
                  value={cpassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
                <span
                  className="input-group-text"
                  onClick={toggleCPasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={showCPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>
                </span>
              </div>
              <div className="my-2">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={verifyCaptcha}
                />
              </div>
              <div className="form-check">
                <input type="checkbox" className="mr-2" onChange={e => acceptTermsCondition(e)}/>
                <label className="form-check-label">
                  <span className="text-muted">Accept All</span> 
                  <Link to={'/terms-of-services'} className="text-decoration-underline ms-1">Terms & Conditions</Link>
                </label>
              </div>
            </div>
            <button
              disabled={!(captcha && termCondition)}
              type="submit"
              className="btn btn-success text-white mt-4"
            >
              SIGN UP TO BEBA MZIGO
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
