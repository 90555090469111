import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer_main">
            <div className="footer">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mb-sm-0">
                            <div className="footer_logo"> 
                                <Link to='/'>
                                    <img className='img-fluid' src="./assets/images/footer-logo.png" alt="" />
                                </Link>
                            </div>
                        </div>
                        {/* <div className="col-6 col-sm-4 col-md-4 col-lg-2">
                            <div className="footer_menu_item1">
                                <ul>
                                    <li className="footer_menu_item1_head">
                                        <a href="#">Services</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="#">Shipper</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="#">Transporter</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="#">Driver</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                            <div className="footer_menu_item1">
                                <ul>
                                    <li className="footer_menu_item1_head">
                                        <a href="#">Support</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/#why-us">About Us</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/#contact-us">Contact Us</a>
                                    </li>
                                    {/* <li className="footer_menu_item1_menu">
                                        <a href="#">FAQ</a>
                                    </li> */}
                                    {/* <li className="footer_menu_item1_menu">
                                        <a href="#">Track Item</a>
                                    </li> */}
                                    <li className="footer_menu_item1_menu">
                                        <a href="/login">Ship Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                            <div className="footer_menu_item1">
                                <ul>
                                    <li className="footer_menu_item1_head">
                                        <a href="#">Policies</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/terms-of-services">Terms of service</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/Privacy">Privacy</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/DriverPrivacy">Driver Privacy</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/ShipperTerms">Shipper terms</a>
                                    </li>
                                    <li className="footer_menu_item1_menu">
                                        <a href="/TransporterTerms">Transporter terms</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="subscribe-form">
                                <form>
                                    <div className="mb-3">
                                        <input type="email" className="form-control" id="subscribe" aria-describedby="emailHelp"
                                            placeholder="Enter your e-mail adress" />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Sign Up</button>
                                </form>
                            </div>

                            <div className='social-media mt-4'>
                                <span>Follow Us On</span>
                                <div className='mt-2 pt-1'>
                                    <a target='_blank' href="https://web.facebook.com/people/Beba-mzigo/61559655251381"><img src="./assets/images/facebook.png" alt="" /></a>
                                    <a target='_blank' href="https://x.com/bebamzigo_ke"><img src="./assets/images/twitter.png" alt="" /></a>
                                    <a target='_blank' href="https://wa.me/254794397129"><img src="./assets/images/whatsapp.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer